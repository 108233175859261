.dashboard {
  display: flex;
  /*background-color: #3B82F6 !important;*/
  /*background-color: #1157cb !important;*/
}

.sidebar {
  width: 250px; /* Adjust the width of the sidebar */
  background-color: #333;
  color: white;
  padding: 1rem;
  padding-top: 5rem;
  overflow: hidden;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 1rem;
}

.main-content {
  flex-grow: 1;
  padding: 1rem;
}
