.card {
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}

.card h2 {
  font-size: 1.5rem;
  margin-top: 0;
}

.card p {
  color: #666;
}

@media (max-width: 1200px) {
  .card {
    width: calc(33.333% - 2rem); /* Adjusted for grid layout */
  }
}

@media (max-width: 768px) {
  .card {
    width: calc(50% - 2rem); /* Adjusted for grid layout */
  }
}

@media (max-width: 480px) {
  .card {
    width: 100%; /* Adjusted for grid layout */
  }
}
