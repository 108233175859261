.transform-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: #3B82F6; /* Tailwind CSS blue-500 */
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
}

.transform-btn:hover {
  background-color: #2563EB; /* Tailwind CSS blue-700 */
}
