.custom-btn {
  background-color: #3B82F6; /* Tailwind CSS blue-500 */
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;

  display: flex;
  flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

  transition: all 0.3s ease-out;
}

.custom-btn:hover {
  background-color: #2563EB; /* Tailwind CSS blue-700 */
  transform: scale(1.05);
}
