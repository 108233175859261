:root {
  --primary-color: #3490dc;
  --secondary-color: #f8f9fa;
  --text-color: #212529;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  color: var(--text-color);
  background-color: var(--secondary-color);
}

.container {
  max-width: 600px;
  margin: auto;
  padding: 2rem;
}

h1,
h2 {
  text-align: center;
}

form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

input[type="text"] {
  flex-grow: 1;
  padding: 0.5rem;
  font-size: 1rem;
}

button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #2c7a9f;
}

.weather-data {
  text-align: center;
  padding: 2rem;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
