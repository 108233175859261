.main-content {
  padding: 1rem;
}

.main-content header {
  text-align: center;
  margin-bottom: 2rem;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: row;
    gap: 3rem;
}

.main-content h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
}