.home-button {
    position: absolute;
    top: 1rem;
    left: 1rem;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    z-index: 2;

    padding: 0.7rem;
    border-radius: 0.7rem;
}